export const report = [
	{
		name: 'Báo cáo tổng quan',
		value: 'OverviewReport',
		// checked: false
	},
	{
		name: 'Số liệu vận hành',
		value: 'OperationalData',
		// checked: false
	},
];

export const category = [
	{
		name: 'Thống kê thiết bị',
		value: 'DeviceStatistics',
		// checked: false
	},
	{
		name: 'Check log nạp GGold',
		value: 'LogPurchaseGGold',
		// checked: false
	},
	{
		name: 'Check log nạp Game',
		value: 'LogPurchaseGame',
		// checked: false
	},
	{
		name: 'Check log nạp Game External',
		value: 'LogPurchaseExternal',
		// checked: false
	},
	{
		name: 'Transaction Report',
		value: 'TransactionReport',
		// checked: false
	},
];

export const project = [
	{
		name: 'Dự án',
		value: 'Project',
		// checked: false
	},
	{
		name: 'Quản lý tài khoản user',
		value: 'ManageUserAccounts',
		// checked: false
	},
	{
		name: 'Log khoá tài khoản',
		value: 'LogAccount',
		// checked: false
	},
	{
		name: 'Thông báo',
		value: 'Notification',
		// checked: false
	},
	{
		name: 'Gửi thư',
		value: 'Mailing',
		// checked: false
	},
	{
		name: 'Quản lý Ticket',
		value: 'ManageTicket',
		// checked: false
	},
];

export const tool = [
	{
		name: 'Phân quyền tài khoản',
		value: 'Accounts',
		// checked: false
	},
	{
		name: 'Quản lý GGold',
		value: 'ManageGGold',
		// checked: false
	},
	{
		name: 'Resend GGold',
		value: 'ResendGGold',
		// checked: false
	},
];

export const menu = [
	{
		_id: 'ALL',
		code: 'Tất cả',
	},
	{
		_id: 'report',
		code: 'Menu Báo cáo',
	},
	{
		_id: 'category',
		code: 'Menu Hạng mục game',
	},
	{
		_id: 'project',
		code: 'Menu Quản lý dự án',
	},
	{
		_id: 'tool',
		code: 'Menu Công cụ',
	},
];
