import './manageuseraccounts.scss';
import { TextField, Box, Input, FormGroup, FormControlLabel, Radio, Switch } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as CustomModal from '../../components/modal/CustomModal';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DataGrid } from '@mui/x-data-grid';
import { mockDataUser } from '../../data/mockDataUser';
import ApiRequest from '../../api/axios';
import Constant from '../../common/constant/constant';
import Message from '../../common/message/message';
import { renameKey } from '../../common/util';
import moment from 'moment';
import { Table } from 'antd';

const ManageUserAccounts = () => {
	const [modalShow, setModalShow] = useState(false);
	const [modalInfoShow, setModalInfoShow] = useState(false);
	const [dataActive, setDataActive] = useState({});
	const [message, setMessage] = useState('Cheat');
	const [userStatus, setUserStatus] = useState(false);
	const [editEmail, setEditEmail] = useState(false);
	const [editPass, setEditPass] = useState(false);
	const [open, setOpen] = useState(false);
	const [isLoadingBtn, setIsLoadingBtn] = useState(false);
	const [inputSearch, setInputSearch] = useState(Constant.EMPTY);
	const [flgInput, setFlgInput] = useState(Constant.LABEL.USERNAME);
	const [userInfo, setUserInfo] = useState({});
	const [enableSecondPassword, setEnableSecondPassword] = useState(false);

    const [rows, setRows] = useState([]);
    const [games, setGames] = useState([]);

	const handleClose = () => setOpen(false);
	const handleRowClick = (params) => {
		setSelectedRow(params.row)
		setOpen(true);
	};

	const columns = [
		{
			field: 'gameId',
			headerName: 'STT',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'order-column--cell',
		},
		{
			field: 'gameTitle',
			headerName: 'Tên game',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'gameTitle-column--cell',
		},
		{
			field: 'id',
			headerName: 'ID game',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'gameId-column--cell',
		},
		{
			field: 'totalMoney',
			headerName: 'Tổng nạp',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'totalMoney-column--cell',
		},
		// {
		// 	field: 'create_char_time',
		// 	headerName: 'Thời gian tạo nhân vật',
		// 	flex: 1,
		// 	headerAlign: 'center',
		// 	align: 'center',
		// 	cellClassName: 'create_char_time-column--cell',
		// },
		// {
		// 	field: 'last_login',
		// 	headerName: 'Đăng nhập lần cuối',
		// 	flex: 1,
		// 	headerAlign: 'center',
		// 	align: 'center',
		// 	cellClassName: 'last_login-column--cell',
		// },
	];

	const handleClickEditEmail = () => {
		if (editEmail) {
			// updateEmail;
			handleUpdateEmail();
			setEditEmail(false);
		} else {
			setEditEmail(true);
		}
	};

	const handleClickEditPass = () => {
		if (editPass) {
			// updateEmail;
			handleUpdatePass();
			setEditPass(false);
		} else {
			setEditPass(true);
		}
	};

	const handleChange = (event) => {
		setFlgInput(event.target.value);
		console.log(event.target.value);
	};

	const handleLock = () => {
		setMessage(Message.MS0007);
		setModalShow(true);
	};

	const handleCloseModal = (e, data) => {
		console.log(data);
		handleLockAccount(data);
		setModalShow(false);
	};

	const handleSearch = async () => {
		if (!inputSearch) return alert(Message.ER0011);
		setIsLoadingBtn(true);
		try {
			if (userInfo) setUserInfo({});
			if (rows?.length > 0) setUserInfo([]);
            const [response, responseGame] = await Promise.all([
                ApiRequest(Constant.GET, `${Constant.PLAYER_URL}/${inputSearch}`),
                ApiRequest(Constant.GET, `${Constant.GAME_URL}`)
            ]);
            setGames(responseGame?.data?.data?.games?.list)
			setIsLoadingBtn(false);
			if (!response?.data) return;
			const data = response?.data?.data?.player;
			const dataHistory = response?.data?.data?.playedHistory;
			if (data) {
				setUserInfo(data);
			}
			if (dataHistory?.length > 0) {
				dataHistory.forEach((obj) => renameKey(obj, '_id', 'id'));
				setRows(dataHistory);
			}
		} catch (e) {
			setIsLoadingBtn(false);
		}
	};

	const handleUpdatePass = async () => {
		let body = {
			secondPassword: userInfo?.secondPassword,
			enableSecondPassword: userInfo?.secondPassword.trim() != "" ? userInfo?.enableSecondPassword : false,
		};
		const response = await ApiRequest(Constant.PUT, Constant.PLAYER_URL + `/updateInfo?id=${userInfo?.id}`, body);
		if (!response?.data) return;
		const data = response?.data?.data?.player;
		console.log(response?.data?.data);
		if (data?.list) {
			setUserInfo(data?.list[0]);
		}
	};

	const handleUpdateEmail = async () => {
		let body = {
			email: userInfo?.email,
		};
		const response = await ApiRequest(Constant.PUT, Constant.PLAYER_URL + `/updateEmail?id=${userInfo?.id}`, body);
		if (!response?.data) return;
		const data = response?.data?.data?.player;
		console.log(response?.data?.data);
		if (data?.list) {
			setUserInfo(data?.list[0]);
		}
	};

	const handleLockAccount = async (reasonContent) => {
		let body = {
			reason: reasonContent,
		};
		const response = await ApiRequest(Constant.POST, Constant.PLAYER_URL + `/block?id=${userInfo?.id}`, body);
		if (!response?.data) return;
		const data = response?.data?.data?.player;
		console.log(response?.data?.data);
		if (data) {
			setUserInfo((previousState) => {
				return { ...previousState, isBanned: true };
			});
		}
	};

	const handleUnLockAccount = async () => {
		let body = {
			reason: userInfo?.reason,
		};
		const response = await ApiRequest(Constant.POST, Constant.PLAYER_URL + `/unblock?id=${userInfo?.id}`, body);
		if (!response?.data) return;
		const data = response?.data?.data?.player;
		console.log(response?.data?.data);
		if (data) {
			setUserInfo((previousState) => {
				return { ...previousState, isBanned: false };
			});
		}
	};

	return (
		<div className="home-manageuseraccounts">
			<Box m="20px">
				<Box display="flex" alignItems="center" flexDirection="row">
					<Box display="flex" justifyContent="start" alignItems="flex-start" flexDirection="column">
						{/* <FormGroup row={false} sx={{ marginLeft: "10px", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                            <FormControlLabel control={<Radio onChange={handleChange} checked={flgInput === Constant.LABEL.USERNAME}
                                value={Constant.LABEL.USERNAME} name="radio-buttons" />} label={Constant.LABEL.USER_NAME} />
                            <FormControlLabel control={<Radio onChange={handleChange} checked={flgInput === Constant.LABEL.IDUSER}
                                value={Constant.LABEL.IDUSER} name="radio-buttons" />} label={Constant.LABEL.PLAYER_ID} />
                        </FormGroup> */}
						<TextField
							placeholder="Nhập Username hoặc ID tài khoản"
							onChange={(e) => setInputSearch(e.target.value)}
							value={inputSearch}
							variant="outlined"
							size="small"
							sx={{ width: '100%' }}
						/>
					</Box>
					<Box display="flex" alignSelf="flex-end" flexDirection="column" ml="10px">
						<Button className="btnRed" onClick={handleSearch} disabled={isLoadingBtn}>
							{isLoadingBtn ? 'Loading ...' : Constant.BUTTON.SEARCH}
						</Button>
					</Box>
				</Box>

				{userInfo?.id && (
					<Box mt="10px" display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
						<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
							<span style={{ fontWeight: 'bold' }}>{Constant.LABEL.PLAYER_INFO}</span>
							<span
								style={{
									color: userInfo?.isBanned ? 'red' : 'green',
								}}
							>
								{Constant.LABEL.STATUS} {userInfo?.isBanned ? Constant.LABEL.LOCKED : Constant.LABEL.NORMAL}
							</span>
						</Box>
					</Box>
				)}
				{userInfo?.id && (
					<Box m="10px" display="flex" alignItems="start" flexDirection="row">
						<Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.USER_NAME}</span>
								<TextField
									value={userInfo?.username || Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PLAYER_ID}</span>
								<TextField
									value={userInfo?.id || Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PLAYER_FULLNAME}</span>
								<TextField
									value={userInfo?.fullName || Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PLAYER_BRITHDAY}</span>
								<TextField
									value={userInfo?.dob ? moment(userInfo?.dob).format('DD-MM-YYYY') : Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PLAYER_GENDER}</span>
								<TextField
									value={userInfo?.gender || Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PLAYER_PHONE}</span>
								<TextField
									value={userInfo?.phone || Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
						</Box>
						<Box ml="10px" display="flex" justifyContent="space-between" flexDirection="column" flex="1">
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PLAYER_ADDRESS}</span>
								<TextField
									value={userInfo?.address || Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PLAYER_IDENTITYCARD}</span>
								<TextField
									value={userInfo?.identityCard || Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PLAYER_IDENTITYCARD_ADDRESSS}</span>
								<TextField
									value={userInfo?.placeOfGrant || Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PLAYER_IDENTITYCARD_DAY}</span>
								<TextField
									value={userInfo?.issueDate ? moment(userInfo?.issueDate).format('DD-MM-YYYY') : Constant.EMPTY}
									variant="standard"
									InputProps={{
										readOnly: true,
									}}
									size="small"
									className="userItem"
								/>
							</Box>
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.EMAIL}</span>
								<Input
									value={userInfo?.email || Constant.EMPTY}
									onChange={(e) =>
										setUserInfo((previousState) => {
											return {
												...previousState,
												email: e.target.value,
											};
										})
									}
									readOnly={!editEmail}
									size="small"
									className={!editEmail ? 'userItem' : 'userItemEdit'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton onClick={handleClickEditEmail}>{editEmail ? <SaveOutlinedIcon /> : <EditOutlinedIcon />}</IconButton>
										</InputAdornment>
									}
								/>
							</Box>
						</Box>
					</Box>
				)}

				{userInfo?.id && (
					<Box m="10px" display="flex" alignItems="center" flexDirection="row">
						<Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
							<Box my="10px" display="flex" flexDirection="row">
								<span className="labelProjectItem " style={{ width: '20%' }}>
									{Constant.LABEL.LOGIN_ADMIN}
								</span>
								<div className="inputProjectItem spanSelect">
									<Switch
										checked={userInfo?.enableSecondPassword}
										onChange={(e) => {
											setUserInfo((previousState) => {
												return {
													...previousState,
													enableSecondPassword: e?.target?.checked,
												};
                                            });
                                            console.log(e?.target?.checked)
											handleUpdatePass();
										}}
									/>
								</div>
							</Box>
						</Box>
					</Box>
				)}

				{userInfo?.enableSecondPassword && (
					<Box m="10px" display="flex" alignItems="center" flexDirection="row">
						<Box display="flex" justifyContent="space-between" flexDirection="column" flex="1">
							<Box my="10px" display="flex" flexDirection="row">
								<span className="userItem borderUserItem">{Constant.LABEL.PASS}</span>
								<Input
									value={userInfo?.secondPassword || Constant.EMPTY}
									onChange={(e) =>
										setUserInfo((previousState) => {
											return {
												...previousState,
												secondPassword: e.target.value,
											};
										})
									}
									readOnly={!editPass}
									size="small"
									className={!editPass ? 'userItem' : 'userItemEdit'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton onClick={handleClickEditPass}>{editPass ? <SaveOutlinedIcon /> : <EditOutlinedIcon />}</IconButton>
										</InputAdornment>
									}
								/>
							</Box>
						</Box>
						<Box display="flex" justifyContent="space-between" flexDirection="column" flex="1"></Box>
					</Box>
				)}
				{/* history */}
				{userInfo?.id && (
					<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
						<Box>
							<span style={{ fontWeight: 'bold' }}>{Constant.LABEL.PLAYER_HISTORY}</span>
						</Box>
						<Box>
							<Button className="btnRed" onClick={handleLock} variant="secondary" disabled={userInfo?.isBanned}>
								{Constant.BUTTON.BLOCK}
							</Button>
							<Button
								className="btnGreen"
								style={{ marginLeft: '10px' }}
								onClick={() => handleUnLockAccount()}
								variant="secondary"
								disabled={!userInfo?.isBanned}
							>
								{Constant.BUTTON.UNBLOCK}
							</Button>
						</Box>
					</Box>
				)}
				{userInfo?.id && (
					<Box
						m="10px 0 0 0"
						height="50vh"
						sx={{
							'& .MuiDataGrid-root': {
								border: 'none',
							},
							'& .MuiDataGrid-cell': {
								borderBottom: 'none',
							},
							'& .MuiDataGrid-columnHeaders': {
								// color: colors.greenAccent[300],
								//borderBottom: "none",
							},
							'& .MuiDataGrid-row': {
								borderBottom: '1px solid rgba(224, 224, 224, 1)',
							},
							'& .MuiTablePagination-toolbar': {
								display: 'flex',
								alignItems: 'baseline',
							},
						}}
					>
						<DataGrid
							rows={rows}
							columns={columns}
							autoPageSize
							onRowClick={(e) => {
								setModalInfoShow(true);
                                // setIndexActive()
                                const datas = [];
                                if (e?.row?.history?.length > 0) {
                                    let data = {};
                                    for (const history of e?.row?.history) {
                                        data = { ...history };
                                        if (history?.gameId) {
                                            data.gameId = games.find(e=> e?._id === history?.gameId).title
                                        }
                                        if (history?.createdAt) {
                                            data.createdAt = moment(history?.createdAt).format('DD/MM/YYYY hh:mm:ss')
                                        }
                                        if (history?.updatedAt) {
                                            data.updatedAt = moment(history?.updatedAt).format('DD/MM/YYYY hh:mm:ss')
                                        }
                                        datas.push(data)
                                    }
                                }
                                console.log('vao day row', datas, e?.row)
								setDataActive(datas);
							}}
						/>
					</Box>
				)}
			</Box>
            <CustomModal.default
				show={modalShow}
				onHide={() => setModalShow(false)}
				message={message}
				btnCancelShow="true"
				btnAgreeShow="true"
				showInputReason={true}
				onCloseModal={handleCloseModal}
			/>

			<Modal show={modalInfoShow} onHide={() => setModalInfoShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header className="headerModal">
					<Modal.Title id="contained-modal-title-vcenter">Chi tiết</Modal.Title>
				</Modal.Header>
				<Modal.Body className="bodyModal">
					<Table
						columns={[
							{
								title: 'Game',
								dataIndex: 'gameId',
								key: 'game',
							},
							{
								title: 'Device',
								dataIndex: 'deviceType',
								key: 'device',
							},
							{
								title: 'PlayerID',
								dataIndex: 'playerId',
								key: 'PlayerID',
							},
							{
								title: 'Thời gian tạo nhân vật',
								dataIndex: 'createdAt',
								key: 'createdAt',
							},
							{
								title: 'Đăng nhập lần cuối',
								dataIndex: 'updatedAt',
								key: 'updatedAt',
							},
						]}
						dataSource={dataActive}
						scroll={{ x: true, y: 300 }}
						bordered
                        size="small"
                        onRow={(e) => {
                            console.log('vao day row', e)
                        }}
                        
					/>
				</Modal.Body>
				<Modal.Footer className="footerModal"></Modal.Footer>
			</Modal>
		</div>
	);
};

export default ManageUserAccounts;
