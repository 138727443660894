export const platformData = [
	{
		_id: 'ANY',
		code: 'Tất cả',
	},
	{
		_id: 'ANDROID',
		code: 'Android',
	},
	{
		_id: 'IOS',
		code: 'IOS',
	},
];

export const purchaseTypeSelect = [
	{
		_id: 'internal',
		code: 'Internal',
	},
	{
		_id: 'external',
		code: 'External',
	}
];

export const messageLockAccount = [
	'Cheat',
	'Nạp lậu',
	'Sử dụng phần mềm hack',
	'Refund trục lợi',
	'Vi phạm tiêu chuẩn cộng đồng',
];

export const gameStatus = [
	{ id: 0, label: 'THỬ NGHIỆM' },
	{ id: 1, label: 'MỞ' },
	{ id: 2, label: 'ĐÓNG' },
	{ id: 3, label: 'BẢO TRÌ' },
];

export const displayData = [
	{
		_id: 'Chart',
		code: 'Biểu đồ',
	},
	{
		_id: 'Grid',
		code: 'Số liệu',
	},
];

export const paymentData = [
	{
		_id: 'ALL',
		code: 'Tất cả',
	},
	//   {
	//     _id: "Bank",
	//     code: "Bank"
	//   },
	{
		_id: 'Momo',
		code: 'Ví Momo',
	},
	{
		_id: 'APPOTA_PAY',
		code: 'Ví APPOTA',
	},
	//   {
	//     _id: "Zalopay",
	//     code: "Zalo Pay"
	//   },
	//   {
	//     _id: "Gate",
	//     code: "Thẻ Gate"
	//   },
	//   {
	//     _id: "Wallet",
	//     code: "Nạp game từ ví"
	//   },
	{
		_id: 'GOOGLE',
		code: 'IAP Google',
	},
	{
		_id: 'APPLE',
		code: 'IAP Apple',
	},
];

export const statuses = [
	{
		_id: 'ALL',
		code: 'Tất cả',
	},
	{
		_id: 'success',
		code: 'Success',
	},
	{
		_id: 'failed',
		code: 'Failed',
	},
	{
		_id: 'pending',
		code: 'Pending',
	}
];

export const paymentSubType = [
	{
		_id: 'ALL',
		code: 'Tất cả',
	},
	//   {
	//     _id: "Bank",
	//     code: "Bank"
	//   },
	{
		_id: 'virtual_bank',
		code: 'Scan QR',
	},
	{
		_id: 'recharge_card',
		code: 'Thẻ cào',
	},
	//   {
	//     _id: "Zalopay",
	//     code: "Zalo Pay"
	//   },
	//   {
	//     _id: "Gate",
	//     code: "Thẻ Gate"
	//   },
	//   {
	//     _id: "Wallet",
	//     code: "Nạp game từ ví"
	//   },
	{
		_id: 'banking',
		code: 'Ngân hàng',
	},
	{
		_id: 'credit_card',
		code: 'Credit card',
	},
];

export const paymentTransactionStatusFilter = [
	{_id: 'all', code: 'Tất cả'},
	{_id: 'failed', code: 'Fail'},
	{_id: 'success', code: 'Success'},
	{_id: 'pending', code: 'Pending'},
]

export const mockDataUsers = [
	{
		id: 1,
		order: 1,
		user_name: 'Jon Snow',
		email: 'jonsnow@gmail.com',
		account_id: 35,
		game: 'GG01',
		sum_ggold: 200000,
	},
	{
		id: 2,
		order: 2,
		user_name: 'Cersei Lannister',
		email: 'cerseilannister@gmail.com',
		account_id: 42,
		game: 'GG02',
		sum_ggold: 10000,
	},
	{
		id: 3,
		order: 3,
		user_name: 'Jaime Lannister',
		email: 'jaimelannister@gmail.com',
		account_id: 45,
		game: 'GG03',
		sum_ggold: 12000,
	},
	{
		id: 4,
		order: 4,
		user_name: 'Anya Stark',
		email: 'anyastark@gmail.com',
		account_id: 16,
		game: 'GG01',
		sum_ggold: 11000,
	},
	{
		id: 5,
		order: 5,
		user_name: 'Daenerys Targaryen',
		email: 'daenerystargaryen@gmail.com',
		account_id: 31,
		game: 'GG01',
		sum_ggold: 50000,
	},
	{
		id: 6,
		order: 6,
		user_name: 'Ever Melisandre',
		email: 'evermelisandre@gmail.com',
		account_id: 150,
		game: 'GG01',
		sum_ggold: 5000,
	},
	{
		id: 7,
		order: 7,
		user_name: 'Ferrara Clifford',
		email: 'ferraraclifford@gmail.com',
		account_id: 44,
		game: 'GG02',
		sum_ggold: 100,
	},
	{
		id: 8,
		order: 8,
		user_name: 'Rossini Frances',
		email: 'rossinifrances@gmail.com',
		account_id: 36,
		game: 'GG02',
		sum_ggold: 6000,
	},
	{
		id: 9,
		order: 9,
		user_name: 'Harvey Roxie',
		email: 'harveyroxie@gmail.com',
		account_id: 65,
		game: 'GG01',
		sum_ggold: 1000000,
	},
];

export const mockDataUserHistory = [
	{
		id: 1,
		order: 1,
		user_name: 'Jon Snow',
		account_id: 35,
		game: 'GG01',
		ggold_before: 50000,
		ggold_after: 200000,
	},
	{
		id: 2,
		order: 2,
		user_name: 'Cersei Lannister',
		account_id: 42,
		game: 'GG02',
		ggold_before: 50000,
		ggold_after: 10000,
	},
	{
		id: 3,
		order: 3,
		user_name: 'Jaime Lannister',
		account_id: 45,
		game: 'GG03',
		ggold_before: 5000,
		ggold_after: 12000,
	},
	{
		id: 4,
		order: 4,
		user_name: 'Anya Stark',
		account_id: 16,
		game: 'GG01',
		ggold_before: 60000,
		ggold_after: 11000,
	},
	{
		id: 5,
		order: 5,
		user_name: 'Daenerys Targaryen',
		account_id: 31,
		game: 'GG01',
		ggold_before: 10000,
		ggold_after: 50000,
	},
	{
		id: 6,
		order: 6,
		user_name: 'Ever Melisandre',
		account_id: 150,
		game: 'GG01',
		ggold_before: 40000,
		ggold_after: 5000,
	},
	{
		id: 7,
		order: 7,
		user_name: 'Ferrara Clifford',
		account_id: 44,
		game: 'GG02',
		ggold_before: 40000,
		ggold_after: 100,
	},
	{
		id: 8,
		order: 8,
		user_name: 'Rossini Frances',
		account_id: 36,
		game: 'GG02',
		ggold_before: 5000,
		ggold_after: 6000,
	},
	{
		id: 9,
		order: 9,
		user_name: 'Harvey Roxie',
		account_id: 65,
		game: 'GG01',
		ggold_before: 70000,
		ggold_after: 1000000,
	},
];

export const mockDataResend = [
	{
		id: 1,
		order: 1,
		transaction_id: 1234,
		game: 'GG01',
		server_game: 'S1-ABC',
		platform: 'IOS',
		amount: 50000,
		time: '03/21/2022 09:00',
		status: 'Fail',
	},
	{
		id: 2,
		order: 2,
		transaction_id: 1234,
		game: 'GG02',
		server_game: 'S2-ABC',
		platform: 'IOS',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Pass',
	},
	{
		id: 3,
		order: 3,
		transaction_id: 1234,
		game: 'GG01',
		server_game: 'S1-ABC',
		platform: 'Android',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Fail',
	},
	{
		id: 4,
		order: 4,
		transaction_id: 1234,
		game: 'GG01',
		server_game: 'S1-ABC',
		platform: 'Android',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Pass',
	},
	{
		id: 5,
		order: 5,
		transaction_id: 1234,
		game: 'GG02',
		server_game: 'S2-ABC',
		platform: 'IOS',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Pass',
	},
	{
		id: 6,
		order: 6,
		transaction_id: 1234,
		game: 'GG02',
		server_game: 'S2-ABC',
		platform: 'IOS',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Pass',
	},
	{
		id: 7,
		order: 7,
		transaction_id: 1234,
		game: 'GG02',
		server_game: 'S2-ABC',
		platform: 'Android',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Pass',
	},
	{
		id: 8,
		order: 8,
		transaction_id: 1234,
		game: 'GG02',
		server_game: 'S2-ABC',
		platform: 'Android',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Pass',
	},
	{
		id: 9,
		order: 9,
		transaction_id: 1234,
		game: 'GG02',
		server_game: 'S2-ABC',
		platform: 'IOS',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Fail',
	},
	{
		id: 10,
		order: 10,
		transaction_id: 1234,
		game: 'GG02',
		server_game: 'S2-ABC',
		platform: 'IOS',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Pass',
	},
	{
		id: 11,
		order: 11,
		transaction_id: 1234,
		game: 'GG02',
		server_game: 'S2-ABC',
		platform: 'Android',
		amount: 100000,
		time: '03/21/2022 09:00',
		status: 'Fail',
	},
];

export const mockDataUser = [
	{
		id: 1,
		order: 1,
		game_name: 'ABC',
		id_game: '1234',
		sum_purchase: 10000,
		create_char_time: '03/12/2022 18:02',
		last_login: '03/20/2022 18:02',
	},
	{
		id: 2,
		order: 2,
		game_name: 'ABCD',
		id_game: '12345',
		sum_purchase: 10000,
		create_char_time: '03/12/2022 18:02',
		last_login: '03/20/2022 18:02',
	},
	{
		id: 3,
		order: 3,
		game_name: 'ABC',
		id_game: '1234',
		sum_purchase: 10000,
		create_char_time: '03/12/2022 18:02',
		last_login: '03/20/2022 18:02',
	},
	{
		id: 4,
		order: 4,
		game_name: 'ABC',
		id_game: '1234',
		sum_purchase: 10000,
		create_char_time: '03/12/2022 18:02',
		last_login: '03/20/2022 18:02',
	},
	{
		id: 5,
		order: 5,
		game_name: 'ABC',
		id_game: '1234',
		sum_purchase: 10000,
		create_char_time: '03/12/2022 18:02',
		last_login: '03/20/2022 18:02',
	},
	{
		id: 6,
		order: 6,
		game_name: 'ABC',
		id_game: '1234',
		sum_purchase: 10000,
		create_char_time: '03/12/2022 18:02',
		last_login: '03/20/2022 18:02',
	},
	{
		id: 7,
		order: 7,
		game_name: 'ABC',
		id_game: '1234',
		sum_purchase: 10000,
		create_char_time: '03/12/2022 18:02',
		last_login: '03/20/2022 18:02',
	},
	{
		id: 8,
		order: 8,
		game_name: 'ABC',
		id_game: '1234',
		sum_purchase: 10000,
		create_char_time: '03/12/2022 18:02',
		last_login: '03/20/2022 18:02',
	},
];

export const mockDataNotification = [
	{
		id: 1,
		title: 'Bảo trì Server',
		game: 'GG02',
		platform: 'IOS',
		create_date: '27/03/2023 09:00',
		creator: 'admin',
		reason: 'Fix lỗi game',
		status: true,
	},
	{
		id: 2,
		title: 'Close Beta',
		game: 'GG01',
		platform: 'Android',
		create_date: '28/03/2023 09:00',
		creator: 'admin',
		reason: 'Close Beta',
		status: false,
	},
];

export const mockDataMailing = [
	{
		id: 1,
		title: 'Sự kiện mới',
		game: 'GG02',
		platform: 'IOS',
		create_date: '27/03/2023 09:00',
		creator: 'admin',
	},
	{
		id: 2,
		title: 'Test',
		game: 'GG01',
		platform: 'Android',
		create_date: '28/03/2023 09:00',
		creator: 'admin',
	},
];

export const mockPurchaseGameData = [
	{
		id: 1,
		order: 1,
		transaction_id: 1234,
		app_id: 14324,
		server_game: 'S1-ABC',
		user_id: 1234,
		user_name: 'AAA',
		role_id: 5345,
		role_name: 'BBBBB',
		gpa: 13487,
		amount: 50000,
		time: '24/01/2022 09:00',
		payment: 'Bank',
		package: '50 kim cương',
		status: 'Pass',
	},
];

export const mockPurchaseGGoldData = [
	{
		id: 1,
		order: 1,
		transaction_id: 1234,
		uid: 123,
		user_name: 'AAA',
		amount: 50000,
		time: '24/01/2022 09:00',
		payment: 'Bank',
		ggold: 5000,
		ggold_purchase: 10000,
		status: 'Pass',
	},
];

export const mockTransactionData = [
	{
		id: 1,
		order: 1,
		transaction_id: 1234,
		app_id: 14324,
		server_game: 'S1-ABC',
		user_id: 1234,
		user_name: 'AAA',
		role_id: 5345,
		role_name: 'BBBBB',
		gpa: 13487,
		amount: 50000,
		time: '24/01/2022 09:00',
		status: 'Pass',
	},
	{
		id: 2,
		order: 2,
		transaction_id: 3566,
		app_id: 1246,
		server_game: 'S1-ABC',
		user_id: 4567,
		user_name: 'CCC',
		role_id: 7689,
		role_name: 'DDDD',
		gpa: 2678,
		amount: 50000,
		time: '24/01/2022 09:00',
		status: 'Fail',
	},
];

export const mockDeviceData = [
	{
		id: 1,
		order: 1,
		time: '2023/03/13',
		device_id: 'abcde',
		platform: 'Android',
		version: '',
		total_user: 60,
	},
	{
		id: 2,
		order: 2,
		time: '2023/03/13',
		device_id: 'abcde',
		platform: 'IOS',
		version: '',
		total_user: 200,
	},
	{
		id: 3,
		order: 3,
		time: '2023/03/14',
		device_id: 'abcde',
		platform: 'Android',
		version: '',
		total_user: 20,
	},
	{
		id: 4,
		order: 4,
		time: '2023/03/14',
		device_id: 'abcde',
		platform: 'IOS',
		version: '',
		total_user: 70,
	},
	{
		id: 5,
		order: 5,
		time: '2023/03/15',
		device_id: 'abcde',
		platform: 'Android',
		version: '',
		total_user: 90,
	},
	{
		id: 6,
		order: 6,
		time: '2023/03/15',
		device_id: 'abcde',
		platform: 'IOS',
		version: '',
		total_user: 160,
	},
	{
		id: 7,
		order: 7,
		time: '2023/03/16',
		device_id: 'abcde',
		platform: 'Android',
		version: '',
		total_user: 65,
	},
	{
		id: 8,
		order: 8,
		time: '2023/03/16',
		device_id: 'abcde',
		platform: 'IOS',
		version: '',
		total_user: 76,
	},
	{
		id: 9,
		order: 9,
		time: '2023/03/17',
		device_id: 'abcde',
		platform: 'Android',
		version: '',
		total_user: 46,
	},
	{
		id: 10,
		order: 10,
		time: '2023/03/17',
		device_id: 'abcde',
		platform: 'IOS',
		version: '',
		total_user: 57,
	},
	{
		id: 11,
		order: 11,
		time: '2023/04/17',
		device_id: 'abcde',
		platform: 'Android',
		version: '',
		total_user: 150,
	},
	{
		id: 12,
		order: 12,
		time: '2023/04/17',
		device_id: 'abcde',
		platform: 'IOS',
		version: '',
		total_user: 86,
	},
	{
		id: 13,
		order: 13,
		time: '2023/05/17',
		device_id: 'abcde',
		platform: 'Android',
		version: '',
		total_user: 204,
	},
	{
		id: 14,
		order: 14,
		time: '2023/05/17',
		device_id: 'abcde',
		platform: 'IOS',
		version: '',
		total_user: 394,
	},
];

export const mockTicketData = [
	{
		id: '1',
		isFavorite: false,
		status: 1,
		title: 'Abc',
		content:
			'test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data Abc - test content data ',
		date: '24/01/2022',
	},
	{
		id: '2',
		isFavorite: true,
		status: 0,
		title: 'Abc2',
		content: 'test2 content data',
		date: '24/01/2022',
	},
	{
		id: '3',
		isFavorite: false,
		status: 2,
		title: 'Abc3',
		content: 'test3 content data',
		date: '24/01/2022',
	},
	{
		id: '4',
		isFavorite: false,
		status: 1,
		title: 'Abc',
		content: 'test content data',
		date: '24/01/2022',
	},
	{
		id: '5',
		isFavorite: true,
		status: 0,
		title: 'Abc2',
		content: 'test2 content data',
		date: '24/01/2022',
	},
	{
		id: '6',
		isFavorite: false,
		status: 2,
		title: 'Abc3',
		content: 'test3 content data',
		date: '24/01/2022',
	},
	{
		id: '7',
		isFavorite: false,
		status: 1,
		title: 'Abc',
		content: 'test content data',
		date: '24/01/2022',
	},
	{
		id: '8',
		isFavorite: true,
		status: 0,
		title: 'Abc2',
		content: 'test2 content data',
		date: '24/01/2022',
	},
	{
		id: '9',
		isFavorite: false,
		status: 2,
		title: 'Abc3',
		content: 'test3 content data',
		date: '24/01/2022',
	},
	{
		id: '10',
		isFavorite: false,
		status: 1,
		title: 'Abc',
		content: 'test content data',
		date: '24/01/2022',
	},
	{
		id: '11',
		isFavorite: true,
		status: 0,
		title: 'Abc2',
		content: 'test2 content data',
		date: '24/01/2022',
	},
	{
		id: '12',
		isFavorite: false,
		status: 2,
		title: 'Abc3',
		content: 'test3 content data',
		date: '24/01/2022',
	},
];

export const mockProjectData = [
	{
		id: 1,
		project_code: 'GG01',
		project_name: 'Dự án GG01',
		game_category: 'Turnbase',
		icon: './asset/icons/1_1.png',
		createdAt: '06/11/2022',
		openDate: '16/01/2023',
		closeDate: '',
		creator: 'admin1',
		status: 'Đóng',
	},
	{
		id: 2,
		project_code: 'GG02',
		project_name: 'Dự án GG02',
		game_category: 'Chiến thuật',
		icon: './asset/icons/1_7.png',
		createdAt: '15/02/2023',
		openDate: '10/03/2023',
		closeDate: '',
		creator: 'admin1',
		status: 'Bảo trì',
	},
	{
		id: 3,
		project_code: 'GG03',
		project_name: 'Dự án GG03',
		game_category: 'MMORPG',
		icon: './asset/icons/1_8.png',
		createdAt: '30/03/2023',
		openDate: '14/04/2023',
		closeDate: '',
		creator: 'admin1',
		status: 'Mở',
	},
	{
		id: 4,
		project_code: 'GG04',
		project_name: 'Dự án GG04',
		game_category: 'MMORPG',
		icon: './asset/icons/1_9.png',
		createdAt: '08/05/2023',
		openDate: '',
		closeDate: '',
		creator: 'admin1',
		status: 'Mở',
	},
];

export const listData = [
	{
		_id: 'all',
		code: 'Total',
		color: '#40ed3c',
	},
	{
		_id: 'rev',
		code: 'REV',
		color: '#ed693c',
	},
	{
		_id: 'dau',
		code: 'DAU',
		color: '#ffe500',
	},
	{
		_id: 'nru',
		code: 'NRU',
		color: '#00ffd1',
	},
	{
		_id: 'pu',
		code: 'PU',
		color: '#000aff',
	},
	{
		_id: 'arpu',
		code: 'ARPU',
		color: '#bd00ff',
	},
	{
		_id: 'arppu',
		code: 'ARPPU',
		color: '#00621b',
	},
	{
		_id: 'retention',
		code: 'RETENTION',
		color: '#941744',
	},
];
