import './logpurchasegame.scss';
import {
	TextField,
	Box,
	FormControl,
	Select,
	MenuItem,
	Typography,
	Grid,
	Pagination,
} from '@mui/material';
import Button from 'react-bootstrap/Button';
import { paymentData, statuses } from '../../data/mockDataUser';
import { useState, useEffect, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from 'antd';
import ApiRequest from '../../api/axios';
import Constant from '../../common/constant/constant';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { SiteContext } from '../../context/SiteContext';
import Modal from '../../components/modal/ModalShowJSON';
import ModalCompleteTransaction from '../../components/modal/ModalCompleteTransaction';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';



const { RangePicker } = DatePicker;

const usePlaceholderStyles = makeStyles((theme) => ({
	placeholder: {
		color: '#aaa',
	},
}));

function CustomFooterStatusComponent(props) {
	return <Box sx={{ p: 1, display: 'flex' }}>Status</Box>;
}

const Placeholder = ({ children }) => {
	const classes = usePlaceholderStyles();
	return <div className={classes.placeholder}>{children}</div>;
};

const LogPurchaseGame = () => {
	const [transactionId, setTransactionId] = useState(Constant.EMPTY);
	const [username, setUserName] = useState(Constant.EMPTY);
	const [gpa, setGPA] = useState(Constant.EMPTY);
	const [type, setType] = useState(Constant.EMPTY);
	const [status, setStatus] = useState(Constant.EMPTY);
	const [from, setFrom] = useState(Constant.EMPTY);
	const [to, setTo] = useState(Constant.EMPTY);
	const [isLoading, setIsLoading] = useState(false);
	const [listPurrchase, setListPurrchase] = useState([]);

	const [page, setPage] = useState(1);
	const [pageTotal, setPageTotal] = useState(2);

	const [open, setOpen] = useState(false);
	const [openCompleteTxModal, setOpenCompleteTxModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState({});
	const handleClose = () => setOpen(false);
	const handleCloseModalCompleteTx = () => setOpenCompleteTxModal(false);
	const getRowDetail = (row) => {
		setSelectedRow(row)
		setOpen(true);
	};

	const handleClickCompleteTxButton = (row) => {
		setSelectedRow(row)
		setOpenCompleteTxModal(true);
	}


	const { state } = useContext(SiteContext);
	const gameId = state?.navbar?.id;

	let listItems = (datas) => {
		if (datas.length === Constant.ZERO) {
			return;
		} else {
			return datas.map((item) => {
				return (
					<MenuItem value={item._id} key={item._id}>
						<span>{item.code}</span>
					</MenuItem>
				);
			});
		}
	};

	const columns = [
		{
			field: '',
			headerName: 'Action',
			flex: 2,
			headerAlign: 'center',
			align: 'center',
			renderCell: ({ row }) => {
				
					return (
            <div
              style={{display: "flex", flexDirection: "row", minWidth: "40px"}}
            >
              <Button
                size="sm"
                variant="primary"
                style={{width: "45px", height: "30px", marginRight: "5px"}}
                onClick={() => getRowDetail(row)}
              >
                <SearchIcon />
              </Button>
              {row.paymentStatus === "pending" && (
                <Button
                  size="sm"
                  variant="outline-success"
                  style={{width: "45px", height: "30px"}}
                  onClick={() => handleClickCompleteTxButton(row)}
                >
                  <CheckIcon />
                </Button>
              )}
            </div>
          );
				}
		},
		{
			field: 'id',
			headerName: 'STT',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'id-column--cell',
		},
		{
			field: 'serverId',
			headerName: 'Server game',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'server_game-column--cell',
		},
		{
			field: 'gameUserId',
			headerName: 'User name',
			flex: 2,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'user_name-column--cell',
		},
		{
			field: 'roleId',
			headerName: 'Role ID',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'role_id-column--cell',
		},
		{
			field: 'roleName',
			headerName: 'Role Name',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'role_name-column--cell',
		},
		{
			field: 'transactionId',
			headerName: 'GPA',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'gpa-column--cell',
		},
		{
			field: 'amount',
			headerName: 'Amount',
			type: 'number',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'amount-column--cell',
		},
		{
			field: 'createdAt',
			headerName: 'Time',
			flex: 1,
			type: 'dateTime',
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'time-column--cell',
			valueFormatter: (params) =>
				dayjs(new Date(params?.value)).format(
					Constant.FORMAT.FULLDATE
				) +
				' ' +
				dayjs(new Date(params?.value)).format(Constant.FORMAT.TIME),
		},
		{
			field: 'type',
			headerName: 'Hình thức nạp',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'payment-column--cell',
		},
		{
			field: 'productId',
			headerName: 'Tên gói',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'package-column--cell',
		},
		{
			field: 'status',
			headerName: 'Status',
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			renderCell: ({ row: { paymentStatus } }) => {
				return (
					<Box
						width="60%"
						m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
					>
						<Typography sx={{ ml: '5px' }}>
							<span style={{ color: paymentStatus === 'success' ? 'green' : 'red' }}>
								{paymentStatus}
							</span>
						</Typography>
					</Box>
				);
			},
		},
	];

	const handleChangeType = (event) => {
		// console.log(event.target.value);
		setType(event.target.value);
	};

	const handleChangeStatus = (event) => {
		setStatus(event.target.value);
	};

	const onDateChange = (date, dateString) => {
		// console.log(dateString);
		setFrom(dateString[0]);
		setTo(dateString[1]);
	};

	const handleSearch = async (page = 1) => {
		try {
			setListPurrchase([]);
			setIsLoading(true);
			let s = `?page=${page}`;
			if (transactionId !== '') s += `&transid=${transactionId}`;
			if (gpa !== '') s += `&transactionId=${gpa}`;
			if (username !== '') s += `&gameUserId=${username}`;
			if (type !== '' && type !== 'ALL') s += `&type=${type}`;
			if (from !== '') s += `&from=${from}`;
			if (to !== '') s += `&to=${to}`;
			if (status !== '' && status !== 'ALL') s += `&status=${status}`;
			let response = await ApiRequest(
				Constant.GET,
				`${Constant.PURCHASE_URL}/${gameId}/list${s}`
			);
			setPage(page);
			setIsLoading(false);
			if (!response?.data) return;
			const data = response?.data?.data?.purchase;
			if (data.list.length > Constant.ZERO) {
				setListPurrchase(data.list);
				setPageTotal(data?.totalPage);
			} else {
				setListPurrchase([]);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	const downloadFile = async () => {
		try {
			setIsLoading(true);
			let s = `?downloadFile=true`;
			if (transactionId !== '') s += `&transid=${transactionId}`;
			if (gpa !== '') s += `&transactionId=${gpa}`;
			if (username !== '') s += `&gameUserId=${username}`;
			if (type !== '' && type !== 'ALL') s += `&type=${type}`;
			if (from !== '') s += `&from=${from}`;
			if (to !== '') s += `&to=${to}`;
			if (status !== '' && status !== 'ALL') s += `&status=${status}`;
			let response = await ApiRequest(
				Constant.GET,
				`${Constant.PURCHASE_URL}/${gameId}/list${s}`
			);
			if (response?.data) {
				const blob = new Blob([response.data], { type: 'text/csv' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `transaction-data-${new Date().valueOf()}.csv`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!state?.navbar?.id) return;
		handleSearch();
	}, [state]);

	return (
		<div className="home-logpurchasegame">
			<Box m="20px">
				<Grid container>
					<Grid item xs={12}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							alignItems="end"
						>
							<Grid item xs={12} md={6} lg={2}>
								<Box>{Constant.LABEL.TRANSACTION_ID}</Box>
								<TextField
									placeholder="Transaction ID"
									onChange={(e) =>
										setTransactionId(e.target.value)
									}
									value={transactionId}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Box>{Constant.LABEL.USER_NAME}</Box>
								<TextField
									placeholder="Nhập User name"
									onChange={(e) =>
										setUserName(e.target.value)
									}
									value={username}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Box>{Constant.LABEL.GPA_CODE}</Box>
								<TextField
									placeholder="Nhập Mã GPA"
									onChange={(e) => setGPA(e.target.value)}
									value={gpa}
									variant="outlined"
									size="small"
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Box>{Constant.LABEL.PAYMENT}</Box>
								<FormControl
									className="inputWidth"
									size="small"
								>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										value={type}
										onChange={handleChangeType}
										displayEmpty
										renderValue={
											type !== Constant.EMPTY
												? undefined
												: () => (
														<Placeholder>
															{
																Constant
																	.PLACEHOLDER
																	.PAYMENT
															}
														</Placeholder>
												  )
										}
									>
										{listItems(paymentData)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6} lg={2}>
								<Box>{Constant.LABEL.STATUS}</Box>
								<FormControl
									className="inputWidth"
									size="small"
								>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										value={status}
										onChange={handleChangeStatus}
										displayEmpty
										renderValue={
											type !== Constant.EMPTY
												? undefined
												: () => (
														<Placeholder>
															{
																Constant
																	.PLACEHOLDER
																	.STATUS
															}
														</Placeholder>
												  )
										}
									>
										{listItems(statuses)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={12} lg={2}>
								<Box>{Constant.LABEL.TIME}</Box>
								<RangePicker
									size="large"
									style={{ backgroundColor: '#F5F8FF' }}
									onChange={onDateChange}
									className="inputWidth"
								/>
							</Grid>
							<Grid item xs={12} md={12} lg={2}>
								<Button
									className="btnRed"
									onClick={() => handleSearch(1)}
									disabled={isLoading}
								>
									{Constant.BUTTON.SEARCH}
								</Button>
							</Grid>
							<Grid item xs={12} md={12} lg={2}>
								<Button
									className="btnInfo"
									onClick={() => downloadFile()}
									disabled={isLoading}
								>
									{Constant.BUTTON.DOWNLOAD_FILE}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Box
					m="10px 0 0 0"
					height="78vh"
					sx={{
						'& .MuiDataGrid-root': {
							border: 'none',
						},
						'& .MuiDataGrid-cell': {
							borderBottom: 'none',
						},
						'& .MuiDataGrid-columnHeaders': {
							// color: colors.greenAccent[300],
							//borderBottom: "none",
						},
						'& .time-column--cell': {
							whiteSpace: 'normal !important',
						},
						'& .time-column--cell .MuiDataGrid-cellContent': {
							textAlign: 'center',
						},
						'& .MuiDataGrid-row': {
							borderBottom: '1px solid rgba(224, 224, 224, 1)',
						},
						'& .MuiTablePagination-toolbar': {
							display: 'flex',
							alignItems: 'baseline',
						},
					}}
				>
					<DataGrid
						rows={listPurrchase}
						columns={columns}
						autoPageSize
						hideFooter={true}
					/>
					<Grid container justifyContent="center">
						<Pagination
							count={pageTotal}
							onChange={(e, v) => {
								console.log('vvvvv', v);
								handleSearch(v);
							}}
						/>
					</Grid>
				</Box>
				<Modal
                show={open}
                onHide={handleClose}
                message={JSON.stringify(selectedRow)}
                btnCancelShow="true"
                onCloseModal={handleClose}
            />
				<ModalCompleteTransaction
                show={openCompleteTxModal}
                onHide={handleCloseModalCompleteTx}
                data={{extraData: selectedRow?.meta?.extraData, purchaseCode: selectedRow?.code}}
                btnCancelShow="true"
                onCloseModal={handleCloseModalCompleteTx}
            />
			</Box>
		</div>
	);
};

export default LogPurchaseGame;
