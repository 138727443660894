const Constant = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',

	LABEL: {
		CURENT_PASSWORD: 'Mật khẩu hiện tại',
		NEW_PASSWORD: 'Nhập mật khẩu mới',
		CONFIRM_PASSWORD: 'Nhập lại mật khẩu',
		ACCOUNT: 'Tài khoản',
		SETTING: 'Cài đặt',
		LOGOUT: 'Đăng xuất',
		ACCOUNT_EMPLOYEE: 'Tài khoản nhân viên',
		ACCOUNT_INFO: 'Thông tin tài khoản',
		ACCOUNT_DEPARTMENT: 'Nhập bộ phận',
		ACCOUNT_ROLE: 'Nhập vị trí',
		ACCOUNT_MENU: 'Giới hạn quyền truy cập',
		ACCOUNT_RECOMMAND: 'Gợi ý',
		PROFILE_INFO: 'Thông tin',
		PROFILE_PASSWORD: 'Mật khẩu',
		PROFILE_CHANGPWD: 'Đổi mật khẩu',
		MENU_REPORT: 'Báo cáo',
		MENU_CATEGORY: 'Hạng mục game',
		MENU_PJMANAGER: 'Quản lý dự án',
		MENU_TOOL: 'Công cụ',
		PURCHASE_CODE: 'Purchase code',
		USER_NAME: 'User name',
		GPA_CODE: 'Mã GPA',
		PAYMENT: 'Hình thức nạp',
		TIME: 'Thời gian',
		PROJECT: 'Dự án',
		PROJECT_NEW: 'Tạo dự án mới',
		PROJECT_EDIT: 'Chỉnh sửa dự án',
		PROJECT_NAME: 'Tên dự án',
		PROJECT_CATEGORY: 'Thể loại',
		PROJECT_CODE: 'Mã dự án',
		PROJECT_ICON: 'Icon dự án',
		PROJECT_OPEN: 'Ngày mở game',
		PROJECT_CLOSE: 'Ngày đóng game',
		START: 'Thời gian bắt đầu',
		END: 'Thời gian kết thúc',
		PROJECT_MANTENANCE: 'Bảo trì',
		PROJECT_IS_SHOW: 'Kiểm duyệt',
		NOTIFICATION: 'Thông Báo',
		REASON: 'Lý do',
		REASON_EN: 'Reason',
		PROCESSING_RADIO_VALUE: 'processing',
		PROCESSED_RADIO_VALUE: 'processed',
		PROCESSING: 'Đang xử lý',
		PROCESSED: 'Đã xử lý',
		NO_PROCESS: 'Chưa xử lý',
		USERNAME: 'username',
		IDUSER: 'iduser',
		GAME: 'Game',
		PLATFORM: 'Platform',
		PLAYER_INFO: 'Thông tin user',
		PLAYER_ID: 'ID tài khoản',
		PLAYER_FULLNAME: 'Họ và tên',
		PLAYER_GENDER: 'Giới tính',
		PLAYER_ADDRESS: 'Địa chỉ',
		PLAYER_IDENTITYCARD: 'CMND/CCCD',
		PLAYER_IDENTITYCARD_ADDRESSS: 'Nơi cấp',
		PLAYER_IDENTITYCARD_DAY: 'Ngày cấp',
		PLAYER_BRITHDAY: 'Ngày sinh',
		EMAIL: 'Email',
		PLAYER_PHONE: 'SĐT',
		PLAYER_HISTORY: 'Lịch sử chơi game của user',
		STATUS: 'Tình trạng:',
		LOCKED: 'Đang khóa',
		NORMAL: 'Bình thường',
		TOTAL_TICKETS: 'Total Tickets',
		TICKETS_FAVORITE: 'Tickets ưu tiên',
		TICKETS_NO_PROCESS: 'Tickets chưa xử lý',
		TICKETS_PROCESSING: 'Tickets đang xử lý',
		TICKETS_PROCESSED: 'Tickets đã xử lý',
		TICKETS_REMOVE: 'Tickets đã xóa',
		DROPDOWN_ID_INSERT: 'insert',
		DROPDOWN_NAME_INSERT: 'Thêm Reason',
		ACCOUNT_NAME: 'Tên',
		DEPARTMENT: 'Bộ phận',
		ROLE: 'Vị trí',
		TOTALGGOLD: 'Tổng số GGold',
		PLAYER_PURCHASEGG_HISTORY: 'Lịch sử nạp GGold của user',
		SHOW: 'Hiển thị',
		TOGGLE_DAY: 'Ngày',
		TOGGLE_MONTH: 'Tháng',
		ALL_DEVICETYPE: 'Tất cả',
		PAYMENT_FORMAT: 'Định dạng nạp',
		USER_ID: 'User ID',
		SERVER_ID: 'Server ID',
		ROLE_ID: 'Role ID',
		AMOUNT: 'Amount',
		IP: 'Địa chỉ IP',
		PACKAGE_ID: 'Package ID',
		SETTING_TEMPLATE_P: 'Cài đặt mẫu báo lỗi',
		TEMPLATE: 'Mẫu văn bản:',
		TEMAPLATE_NAME: 'Tên Mẫu',
		TEMPLATE_CONTENT: 'Nội dung',
		SETTING_GAME_CATEGORY: 'Cài đặt thể loại game',
		GAME_CATEGORY_P: 'Thể loại:',
		FAIL: 'Fail',
		PASS: 'Pass',
		URL_GAME_INFO: 'Url GameInfo',
		URL_GAME: 'Url Game',
		URL_PAYMENT: 'Url Deposit',
        URL_SERVICE: 'Url Service',
        LOGIN_ADMIN: 'Login Admin'
	},

	NAME: {
		SELECT_GAME: 'game',
		SELECT_PLATFORM: 'platform',
		SELECT_PAYMENT: 'payment',
	},

	FIELD: {
		STATUS: 'statusMessage',
	},

	PLACEHOLDER: {
		PAYMENT: 'Nhập nguồn nạp',
		PLATFORM: 'Nhập platform',
		PURCHASE_CODE: 'Nhập Purchase code',
		USERID: 'Nhập User ID',
		USERNAME: 'Nhập User name',
		SERVERID: 'Nhập Server ID',
		ROLEID: 'Nhập Role ID',
		AMOUNT: 'Nhập Amount',
		GPA: 'Nhập Mã GPA',
		IP: 'Nhập IP',
		PACKAGEID: 'Nhập Package ID',
		NAME: 'Nhập tên',
		EMAIL: 'Nhập email',
		DEPARTMENT: 'Nhập bộ phận',
		ROLE: 'Nhập vị trí',
		CURRENT_PASSWORD: 'Nhập mật khẩu hiện tại',
		NEW_PASSWORD: 'Nhập mật khẩu mới',
		CONFRIM_PASSWORD: 'Nhập lại mật khẩu',
	},

	FORMAT: {
		TIME: 'HH:mm',
		FULLDATE: 'DD/MM/YYYY',
		DATEMONTH: 'YYYY-MM',
		DATETIME: 'HH:mm - DD/MM/YYYY',
	},

	BUTTON: {
		ADD_NEW_ACCOUNT: 'Thêm mới',
		CONFIRM: 'Xác nhận',
		UPDATE: 'Cập nhật',
		SEARCH: 'Tìm kiếm',
		REPLY: 'Trả lời',
		CANCEL: 'Hủy',
		AGREE: 'Đồng ý',
		CLOSE: 'Đóng',
		BLOCK: 'Khoá',
		UNBLOCK: 'Mở khóa',
		SEND: 'Gửi',
		RESET: 'Reset',
		INCREASE: 'Tăng',
		DECREASE: 'Giảm',
		CREATE: 'Tạo mới',
		SAVE: 'Lưu',
		CLEAR: 'Clear',
		EXPORT: 'Export',
	},

	VALUE: {
		ALL: 'all',
		ANDROID: 'ANDROID',
		IOS: 'IOS',
		REPORT: 'report',
		CATEGORY: 'category',
		PROJECT: 'project',
		TOOL: 'tool',
		TYPE_REPORT: 'report',
		TYPE_CATEGORY: 'category',
		TYPE_PROJECT: 'pjmanager',
		TYPE_TOOL: 'tool',
		TOGGLE_DAY: 'day',
		TOGGLE_MONTH: 'month',
		DATEFORMAT: 'YYYY-MM-DD',
	},

	EMPTY: '',
	ANY: 'ANY',
	NULL: null,
	EMPTY_SELECTED: -1,
	ZERO: 0,
	STATUS_NO_PROCESS: 0,
	STATUS_PROCESSING: 1,
	STATUS_PROCESSED: 2,

	ERROR_CODE_400: 400,
	ERROR_CODE_401: 401,

	PATH_SLASH: '/',
	PATH_ASTERISK: '*',
	PATH_LOGIN: 'login',
	PATH_REGISTER: 'register',
	PATH_PROFILE: 'profile',
	PATH_CHANGEPWD: 'changepwd',
	PATH_SETTING: 'setting',
	PATH_OVERVIEWREPORT: 'overviewreport',
	PATH_OPERATIONALDATA: 'operationaldata',
	PATH_DEVICESTATISTICS: 'devicestatistics',
	PATH_LOGPURCHASEGGOLD: 'logpurchaseggold',
	PATH_LOGPURCHASEGAME: 'logpurchasegame',
	PATH_LOGPURCHASEEXTERNAL: 'logpurchaseexternal',
	PATH_TRANSACTIONREPORT: 'transactionreport',
	PATH_PROJECT: 'project',
	PATH_EDITPROJECT: 'editproject',
	PATH_MANAGEUSERACCOUNTS: 'manageuseraccounts',
	PATH_LOGACCOUNT: 'logaccount',
	PATH_NOTIFICATION: 'notification',
	PATH_MAILING: 'mailing',
	PATH_MANAGETICKET: 'manageticket',
	PATH_TICKET: 'ticket',
	PATH_ACCOUNTS: 'accounts',
	PATH_ACCOUNTDETAIL: 'accountdetail',
	PATH_MANAGEGGOLD: 'manageggold',
	PATH_RESENDGGOLD: 'resendggold',

	LOGIN_URL: 'login',
	ACCOUNTS_URL: 'accounts',
	ACCOUNTS_LOCKLOGIN_URL: 'accounts/locklogin',
	ACCOUNTSDETAIL_URL: 'accounts/account',
	PROFILE_URL: 'profile/my',
	CHANGEPWD_URL: 'changepwd',
	SETTING_URL: 'setting',
	GAME_URL: 'game',
	GAMEDETAIL_URL: 'game/detail',
	GAMECATEGORY_URL: 'gamecategory',
	PACKAGE_URL: 'package-game',
	MENU_URL: 'menu',
	MENUBYCODE_URL: 'menu/bycode',
	LOGOUT_URL: 'logout',
	PURCHASE_URL: 'purchase',
	REASON_URL: 'reason',
	NOTIFICATION_URL: 'notification',
	PLAYER_URL: 'player',
	TICKETS_URL: 'tickets',
	MAIL_URL: 'mail',
	MANAGERGGOLD_URL: 'managerggold',
	LOGGGOLD_URL: 'logggold',
	DEVICESTATISTICS_URL: 'devicestatistics',
	WHITE_LIST_URL: 'whitelist',

	FONTCOLOR: {
		GREEN: 'green',
		RED: 'red',
		GREY: 'grey',
	},

	REGEXP: {
		HTMLCHECK: /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/,
	},

	MESSAGE_SUCCESS: 'Cập nhật thành công!',
};

export default Constant;
